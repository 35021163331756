import dayjs from 'dayjs';
import { InputType } from '../components';
import { constants } from './constants';
import { DateType, ParticipantProps } from './types';

export enum Environment {
    Test = 'Test',
    Fix = 'Fix',
    Prod = 'Prod',
}

export const getNumberFromString = (input: string | number) => {
    if (typeof input === 'number') {
        return input;
    }
    return parseInt(input.toString().replace(/ /g, '').trim());
};

export const formatPrice = (x: string | number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const validateField = (
    value: string | number,
    optional?: boolean,
    min?: string | number | null,
    max?: string | number | null,
    variant?: string
) => {
    if (((!value && value !== 0) || value === '') && !optional) {
        return 'Toto pole je povinné';
    }

    if ((value || value === 0) && (min || min === 0) && (max || max === 0)) {
        const numberValue = getNumberFromString(value);

        if (numberValue > Number(max) || numberValue < Number(min)) {
            return `Vyberte hodnotu v rozmezí <span class="text--nowrap">${
                variant === InputType.PRICE ? formatPrice(min) : min
            }</span> a <span class="nowrap">${variant === InputType.PRICE ? formatPrice(max) : max}</span>`;
        }
    }

    return null;
};

export const formatPhoneNumber = (obj: ParticipantProps | string) => {
    let phoneNumberString: string;
    let prefix: string | undefined;
    if (typeof obj === 'string') {
        phoneNumberString = obj;
    } else {
        phoneNumberString = obj.Contact.Phone ?? '';
        prefix = obj.Contact.PhonePrefix.Prefix;
    }
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{3})$/);
    if (match) {
        return (prefix && prefix.length > 0 ? prefix + ' ' : '') + match[1] + ' ' + match[2] + ' ' + match[3];
    }
    return null;
};

export const removeWhitespace = (input: string) => {
    return input.toString().replace(/ /g, '').trim();
};

export const formatDate = (date: DateType) => {
    if (date) {
        return `${date.split('-').reverse().join('.')}`;
    }
    return '';
};

export const parseJson = (value: string) => {
    try {
        return JSON.parse(value);
    } catch (e) {
        return null;
    }
};

export const getBaseUrl = () => {
    if (window && window.location.origin) {
        const hostname = window.location.hostname;
        if (isPoland()) {
            return constants.TEST_API_URL_PL;
        }
        if (hostname === 'localhost' || hostname === '127.0.0.1') {
            return constants.TEST_API_URL_CZ;
        }
        return `${window.location.origin}`;
    }
    return '/';
};

export const formatSimpleaDate = (date?: Date) => {
    if (date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return year + '-' + month + '-' + day;
    }
    return null;
};

export const isDevelopment = () =>
    ['localhost', '127.0.0.1'].includes(window.location.hostname) || process.env.REACT_APP_ENV === 'development';

export const getEnvironment = (): Environment => {
    const origin = window.location.hostname;
    if (isDevelopment() || origin.split('-').includes('test')) return Environment.Test;
    if (origin.split('-').includes('fix')) return Environment.Fix;
    return Environment.Prod;
};

export const isPoland = (): boolean => process.env.REACT_APP_COUNTRY === 'pl';

export const getDataTest = (props: any, suffix?: string) =>
    'data-test' in props ? `${props['data-test']}${suffix || ''}` : undefined;

export const isTouchDevice = (): boolean => {
    return (
        (window && 'ontouchstart' in window) ||
        (navigator && navigator.maxTouchPoints > 0) ||
        (navigator && (navigator as any).msMaxTouchPoints > 0)
    );
};

export const getMissingFormData = (fields: { [key: string]: string }, values: any): string[] =>
    Object.keys(fields).filter((f) => !values[f]);

export const subtractDate = (value: number, unit: dayjs.ManipulateType) => {
    return dayjs().subtract(value, unit).format(constants.DATE_FORMAT);
};

export const addDate = (value: number, unit: dayjs.ManipulateType) => {
    return dayjs().add(value, unit).format(constants.DATE_FORMAT);
};

export const nowDate = () => {
    return dayjs().format(constants.DATE_FORMAT);
};
