import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Field, Form } from 'formik';
import { Loader } from '@simplea/shared-ui';
import { Box, Divider, FormikTextInput, SelectCountry, Switch } from '../../../components';
import { Layout } from '../../../components/Poland/Layout/Layout';
import usePolandParams from '../../../hooks/usePolandParams';
import ChangeBankPopup from './ChangeBankPopup';
import { ROUTES } from '../../routes';
import BackButton from '../../../components/BackButton/BackButton';
import { SelectorSex } from './SelectSex';
import FormikDateInput from '../../../components/DateInput/FormikDateInput';
import { SelectIdentificatorType } from './SelectIdentificatorType';
import { MojeIdContext } from '../../../context/MojeIdContext';
import { MojeIdAddress, MojeIdBankAccount } from '../../../utils/types';
import { ErrorCodes } from '../../../utils/constants';
import RequiredInputsInfo from './RequiredInputsInfo';
import { getMissingFormData } from '../../../utils';
import MissingMojeIdDataError from './MissingMojeIdDataError';
import ErrorBox from '../../../components/ErrorBox/ErrorBox';
import Button from './Button';
import Tooltip from '../../../components/Tooltip';

export interface PersonalDataFormData {
    name: string | null;
    surname: string | null;
    identificatorType: number | null;
    identificatorNumber: string | null;
    sex: number | null;
    dateOfBirth: string | null;
    stateOfBirth: string | null;
    cityOfBirth: string | null;
    nationality: string | null;
    secondCitizenship: string | null;
    // trvalé bydliště
    street: string | null;
    houseNumber: string | null;
    city: string | null;
    zipCode: string | null;
    country: string | null;
    // kontaktní adresa
    anotherContactAddress: boolean | null;
    contactStreet: string | null;
    contactHouseNumber: string | null;
    contactCity: string | null;
    contactZipCode: string | null;
    contactCountry: string | null;
    // bankovní účet
    bankAccountNumber: string | null;
}

interface PersonalDataFormProps {
    values: any;
    error?: string | null;
    disabled?: boolean;
    setFieldValue: (name: string, value: string | boolean) => void;
    onSubmit: () => void;
}

const PersonalDataForm = ({ values, error, disabled = false, setFieldValue, onSubmit }: PersonalDataFormProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { mojeIdData, mojeIdValidate, clientData, setClientData } = useContext(MojeIdContext);
    const [displayChangeBankPopup, setDisplayChangeBankPopup] = useState(false);
    const { onlinePolicyExternalId, participantExternalId, requestIdentifier } = usePolandParams(true);
    const [manuallyEnabled, setManuallyEnabled] = useState(false);

    const isFromSanctionRegion = error === 'isFromSanctionRegion';

    const dateOfBirthInvalid = useMemo(
        () => mojeIdValidate && mojeIdValidate?.ErrorCodes?.includes(ErrorCodes.WrongBirthDate),
        [mojeIdValidate]
    );

    // osobní údaje
    const personalData = mojeIdData && mojeIdData?.PersonalIdentification;

    // trvalé bydliště
    const mojeIdPersonalAddress =
        mojeIdData && mojeIdData?.PermanentAddresses?.length > 0 ? mojeIdData.PermanentAddresses[0] : null;
    const clientPersonalAddress =
        clientData && clientData?.PermanentAddresses?.length > 0 ? clientData.PermanentAddresses[0] : null;

    // kontaktní adresa
    const mojeIdContactAddress =
        mojeIdData && mojeIdData?.ContactAddresses?.length > 0 ? mojeIdData.ContactAddresses[0] : null;
    const clientContactAddress =
        clientData && clientData?.ContactAddresses?.length > 0 ? clientData.ContactAddresses[0] : null;

    // iban
    const clientBankAccount = clientData && clientData?.BankAccounts?.length > 0 ? clientData.BankAccounts[0] : null;

    const handleGoToBanks = () => {
        navigate(
            `/moje-id?onlinePolicyExternalId=${onlinePolicyExternalId}&participantExternalId=${participantExternalId}`
        );
    };

    const handleChangeBank = () => {
        setDisplayChangeBankPopup(true);
    };

    const handleGoBack = () => {
        navigate({
            pathname: ROUTES.mojeidConfirm,
            search: `?onlinePolicyExternalId=${onlinePolicyExternalId}&participantExternalId=${participantExternalId}&requestIdentifier=${requestIdentifier}`,
        });
    };

    useEffect(() => {
        if (clientData) {
            const newClientData = {
                ...clientData,
                PersonalIdentification: {
                    ...clientData.PersonalIdentification,
                    Sex: values.sex,
                    BirthCountryCode: values.stateOfBirth,
                    Birthplace: values.cityOfBirth,
                    CitizenshipCode: values.nationality,
                    SecondCitizenshipCode: values.secondCitizenship,
                },
            };

            // trvalé bydliště
            const newPermanentAddress: MojeIdAddress = {
                RuianReferenceCode: null,
                ...(clientPersonalAddress || {}),
                CityName: values.city,
                CountryCode: values.country,
                HouseNo: values.houseNumber,
                StreetName: values.street,
                Zip: values.zipCode,
            };
            const newPermanentAddresses =
                clientData && clientPersonalAddress
                    ? clientData.PermanentAddresses.map((v, i) => (i === 0 ? newPermanentAddress : v))
                    : [newPermanentAddress];
            newClientData.PermanentAddresses = newPermanentAddresses;

            // kontaktní adresa
            if (values.anotherContactAddress) {
                const newContactAddress: MojeIdAddress = {
                    RuianReferenceCode: null,
                    ...(clientContactAddress || {}),
                    CityName: values.contactCity,
                    CountryCode: values.contactCountry,
                    HouseNo: values.contactHouseNumber,
                    StreetName: values.contactStreet,
                    Zip: values.contactZipCode,
                };
                const newContactAddresses =
                    clientData && clientContactAddress
                        ? clientData.ContactAddresses.map((v, i) => (i === 0 ? newContactAddress : v))
                        : [newContactAddress];
                newClientData.ContactAddresses = newContactAddresses;
            }

            // iban
            const newBankAccount: MojeIdBankAccount = {
                ...(clientBankAccount || { Prefix: null, BankCode: null }),
                BankAccountNo: values.bankAccountNumber?.replace(/^PL/, ''),
                Iban: values.bankAccountNumber,
            };
            const newBankAccounts =
                clientData && clientBankAccount
                    ? clientData.BankAccounts.map((v, i) => (i === 0 ? newBankAccount : v))
                    : [newBankAccount];
            newClientData.BankAccounts = newBankAccounts;

            // uložení do contextu
            setClientData(newClientData);
        }
        // eslint-disable-next-line
    }, [values]);

    useEffect(() => {
        setManuallyEnabled(true);
    }, [values.sex]);

    const handleSubmit = () => {
        setManuallyEnabled(false);
        onSubmit();
    };

    if (!mojeIdData) {
        return (
            <Layout
                headline={t('personalData.title')}
                description={t('personalData.subtitle')}
                footerContent={
                    <Fragment>
                        <Button variant="primary" outlined disabled data-test="changeBankButton">
                            {t('personalData.changeBank')}
                        </Button>
                        <Button variant="primary" disabled data-test="confirmAndSendButton">
                            {t('personalData.confirmAndSend')}
                        </Button>
                    </Fragment>
                }
            >
                <Loader size="medium" className="mt-[30%]" />
            </Layout>
        );
    }

    const requiredFields = {
        name: t('personalData.name'),
        surname: t('personalData.surname'),
        identificatorType: t('personalData.identificatorType'),
        identificatorNumber: t('personalData.identificatorNumber'),
        dateOfBirth: t('personalData.dateOfBirth'),
    };

    const missingFormData = getMissingFormData(requiredFields, values);

    const submitButton = (
        <Button
            variant="primary"
            disabled={(disabled || !!dateOfBirthInvalid || missingFormData.length > 0 || !!error) && !manuallyEnabled}
            data-test="confirmAndSendButton"
            onClick={handleSubmit}
        >
            {t('personalData.confirmAndSend')}
        </Button>
    );

    return (
        <Layout
            headline={t('personalData.title')}
            description={t('personalData.subtitle')}
            headerContent={<BackButton onClick={handleGoBack} />}
            footerContent={
                <Fragment>
                    <Button
                        variant="primary"
                        outlined
                        disabled={disabled}
                        data-test="changeBankButton"
                        onClick={handleChangeBank}
                    >
                        {t('personalData.changeBank')}
                    </Button>
                    {isFromSanctionRegion ? (
                        <Tooltip content={t('personalData.sanctionRegionTooltip')} width="small">
                            {submitButton}
                        </Tooltip>
                    ) : (
                        submitButton
                    )}
                </Fragment>
            }
        >
            {error && <ErrorBox>{t(`general.errors.${error}`)}</ErrorBox>}

            <MissingMojeIdDataError fields={requiredFields} />

            <Box data-test="personalDataCard">
                <RequiredInputsInfo />

                <h3 className="m-0" data-test="personalDataCardTitle">
                    {t('personalData.boxTitle')}
                </h3>
                <Form style={{ width: '100%' }}>
                    <div className="grid">
                        <FormikTextInput
                            name="name"
                            label={t('personalData.name')}
                            disabled
                            required
                            data-test="name"
                        />
                        <FormikTextInput
                            name="surname"
                            label={t('personalData.surname')}
                            disabled
                            required
                            data-test="surname"
                        />
                        <SelectIdentificatorType
                            name="identificatorType"
                            label={t('personalData.identificatorType')}
                            disabled
                            required
                            data-test="identificatorType"
                        />
                        <FormikTextInput
                            name="identificatorNumber"
                            label={t('personalData.identificatorNumber')}
                            disabled
                            required
                            data-test="identificatorNumber"
                        />
                        <SelectorSex
                            name="sex"
                            label={t('personalData.sex')}
                            disabled={!!personalData?.Sex || disabled}
                            required
                            data-test="sex"
                        />
                        <FormikDateInput
                            name="dateOfBirth"
                            label={t('personalData.dateOfBirth')}
                            disabled
                            required
                            errorMessage={dateOfBirthInvalid ? t('personalData.dateOfBirthInvalid') : undefined}
                            data-test="dateOfBirth"
                        />
                        <SelectCountry
                            name="stateOfBirth"
                            label={t('personalData.stateOfBirth')}
                            disabled={!!personalData?.BirthCountryCode || disabled}
                            required
                            data-test="stateOfBirth"
                        />
                        <FormikTextInput
                            name="cityOfBirth"
                            label={t('personalData.cityOfBirth')}
                            disabled={!!personalData?.Birthplace || disabled}
                            required
                            data-test="cityOfBirth"
                        />
                        <SelectCountry
                            name="nationality"
                            label={t('personalData.nationality')}
                            disabled={!!personalData?.CitizenshipCode || disabled}
                            required
                            data-test="nationality"
                        />
                        <SelectCountry
                            name="secondCitizenship"
                            label={t('personalData.secondCitizenship')}
                            disabled={!!personalData?.SecondCitizenshipCode || disabled}
                            data-test="secondCitizenship"
                        />
                    </div>

                    <h3 className="m-0 mt-50" data-test="registeredAddressTitle">
                        {t('personalData.registeredAddress')}
                    </h3>
                    <div className="grid">
                        <FormikTextInput
                            name="street"
                            label={t('personalData.street')}
                            disabled={!!mojeIdPersonalAddress?.StreetName || disabled}
                            required
                            data-test="street"
                        />
                        <FormikTextInput
                            name="houseNumber"
                            label={t('personalData.houseNumber')}
                            disabled={!!mojeIdPersonalAddress?.HouseNo || disabled}
                            required
                            data-test="houseNumber"
                        />
                    </div>
                    <div className="grid grid-3-columns">
                        <FormikTextInput
                            name="city"
                            label={t('personalData.city')}
                            disabled={!!mojeIdPersonalAddress?.CityName || disabled}
                            required
                            data-test="city"
                        />
                        <FormikTextInput
                            name="zipCode"
                            label={t('personalData.zipCode')}
                            disabled={!!mojeIdPersonalAddress?.Zip || disabled}
                            required
                            data-test="zipCode"
                        />
                        <SelectCountry
                            name="country"
                            label={t('personalData.country')}
                            disabled={!!mojeIdPersonalAddress?.CountryCode || disabled}
                            required
                            data-test="country"
                        />
                    </div>

                    <div className="grid grid-full mt-50-force">
                        <Field
                            component={Switch}
                            name="anotherContactAddress"
                            label={t('personalData.anotherContactAddress')}
                            isCenter
                            disabled={disabled}
                            className="mx-auto"
                            data-test="anotherContactAddress"
                        />
                    </div>

                    {values.anotherContactAddress && (
                        <Fragment>
                            <div className="grid">
                                <FormikTextInput
                                    name="contactStreet"
                                    label={t('personalData.street')}
                                    disabled={!!mojeIdContactAddress?.StreetName || disabled}
                                    data-test="contactStreet"
                                />
                                <FormikTextInput
                                    name="contactHouseNumber"
                                    label={t('personalData.houseNumber')}
                                    disabled={!!mojeIdContactAddress?.HouseNo || disabled}
                                    data-test="contactHouseNumber"
                                />
                            </div>
                            <div className="grid grid-3-columns">
                                <FormikTextInput
                                    name="contactCity"
                                    label={t('personalData.city')}
                                    disabled={!!mojeIdContactAddress?.CityName || disabled}
                                    data-test="contactCity"
                                />
                                <FormikTextInput
                                    name="contactZipCode"
                                    label={t('personalData.zipCode')}
                                    disabled={!!mojeIdContactAddress?.Zip || disabled}
                                    data-test="contactZipCode"
                                />
                                <SelectCountry
                                    name="contactCountry"
                                    label={t('personalData.country')}
                                    disabled={!!mojeIdContactAddress?.CountryCode || disabled}
                                    data-test="contactCountry"
                                />
                            </div>
                        </Fragment>
                    )}

                    <Divider className="mt-50-force" />

                    <h3 className="m-0 mt-50" data-test="bankAccountTitle">
                        {t('personalData.bankAcount')}
                    </h3>
                    <div className="grid grid-full">
                        <FormikTextInput
                            name="bankAccountNumber"
                            label={t('personalData.bankAccountNumber')}
                            disabled={disabled}
                            data-test="bankAccountNumber"
                            onBlur={() => {
                                setFieldValue('bankAccountNumber', values.bankAccountNumber.replaceAll(' ', ''));
                            }}
                        />
                    </div>
                </Form>
            </Box>
            {displayChangeBankPopup && (
                <ChangeBankPopup onSubmit={handleGoToBanks} onClose={() => setDisplayChangeBankPopup(false)} />
            )}
        </Layout>
    );
};

export default PersonalDataForm;
