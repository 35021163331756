import React, { useContext } from 'react';
import FormikSelectInput from '../../../components/Input/FormikSelectInput';
import { MojeIdContext } from '../../../context/MojeIdContext';

interface SelectSexProps {
    name: string;
    label: string;
    disabled?: boolean;
    required?: boolean;
}

export const SelectorSex = (props: SelectSexProps) => {
    const { initData } = useContext(MojeIdContext);

    const { Sex } = initData?.Lovs || {};

    return (
        <FormikSelectInput
            isCenter
            options={(Sex || [])
                .filter((sex: any) => sex.Code !== 'U')
                .map((sex: any) => ({
                    value: sex.Id,
                    label: sex.NameTranslated,
                }))}
            {...props}
        />
    );
};
