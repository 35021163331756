import { Button, Loader } from '@simplea/shared-ui';
import { useEffect, useState } from 'react';
import { Layout } from '../../../components';
import { Box } from '../../../components';
import { List } from '../../../components';
import './BankIdIntro.styles.css';
import { Modal } from '../../../components';
import { useModal } from '../../../components/Modal/useModal';
import { BankIdModalContent } from '../../../components/Modal/content/BankIdModalContent';
import { useBankId } from '../../../hooks/useBankId';
import { getBaseUrl } from '../../../utils';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { constants } from '../../../utils/constants';
import { Invalid } from '../../Invalid/Invalid';
import { BankIdFooter } from '../../../components/Modal/footers/BankIdFooter';
import { errorLayoutProps } from '../Processing/utils';
import { useTranslation } from 'react-i18next';

export enum CheckAmlProcessResult {
    IsAvailable = 1,
    ProcessDoesntExist = 2,
    NotAvailable = 3,
}

const listItems = [
    'Na stránce výběru bank zvolíte svou banku, přes kterou se chcete ověřit.',
    'V prostředí banky zadáte přihlašovací údaje stejným způsobem jako do internetového bankovnictví.',
    'V prostředí banky potvrdíte, že souhlasíte s předáním osobních údajů.',
    'Jste úspěšně ověření.',
];

export const BankIdIntro = () => {
    const [submitting, setSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { isOpen, setIsOpen } = useModal();
    const [searchParams] = useSearchParams();
    const [result, setCheckResult] = useState<CheckAmlProcessResult | undefined>();
    const { t } = useTranslation();

    const processId = searchParams.get(constants.PROCESS_ID_QUERY_PARAM);

    const isLinkInvalid = result !== CheckAmlProcessResult.IsAvailable;

    const { bankIdUrl } = useBankId();

    const onOpenBankId = () => {
        setSubmitting(true);
        bankIdUrl && window && window.open(bankIdUrl, '_self');
    };

    useEffect(() => {
        axios
            .get(
                // NOTE: .../check-identification-process-availability/1... - 1 = BankId, 2 = Portal
                `${getBaseUrl()}/api/client-aml-identification/check-identification-process-availability/1/${processId}`
            )
            .then((res) => {
                setCheckResult(res.data);
            })
            .catch(() => {
                setCheckResult(CheckAmlProcessResult.ProcessDoesntExist);
            })
            .finally(() => setIsLoading(false));
    }, [processId]);

    return isLinkInvalid || isLoading ? (
        <Layout {...errorLayoutProps(isLinkInvalid && !isLoading, t)}>
            <div className="processingContent">{isLoading ? <Loader size="medium" /> : <Invalid />}</div>
        </Layout>
    ) : (
        <Layout
            isBankId
            headline="Ověření totožnosti přes BankID"
            description="Ověření vaší totožnosti přes BankID je nejrychlejší a zcela bezpečný způsob, jakým můžeme vaší identifikaci provést a to během pár minut."
        >
            <Box>
                <h3 className="title">
                    Ověření přes BankID
                    <div onClick={() => setIsOpen(true)} className="help">
                        ?
                    </div>
                </h3>
                <div className="listContent">
                    <List items={listItems} />
                </div>
                <Button loading={submitting} onClick={onOpenBankId} disabled={!bankIdUrl}>
                    Pokračovat s Bank ID
                </Button>
            </Box>
            {isOpen && (
                <Modal
                    content={<BankIdModalContent />}
                    heading="Co je BankID"
                    setIsOpen={setIsOpen}
                    footer={<BankIdFooter />}
                />
            )}
        </Layout>
    );
};
