export const constants = {
    REQUIRED_FIELD: 'Toto pole je povinné',
    REQUIRED_VALID_DATE_FIELD: 'Zadejte platné datum',
    ISSUE_DATE_GT_TODAY: 'Zadané datum vydání dokladu je pozdější než dnešní datum.',
    EMAIL_FIELD: 'Zadejte e-mail ve správném formátu',
    CELLPHONE_NUMBER: 'Zadejte mobilní telefonní číslo ve správném formátu.',
    TEST_API_URL_CZ: 'https://sp-test-simidecz-ne-app01.azurewebsites.net',
    // TEST_API_URL_PL: 'https://sp-test-simagepl-ne-app01.azurewebsites.net',
    TEST_API_URL_PL: 'https://sp-test-simidepl-ne-app01.azurewebsites.net',
    PROCESS_ID_QUERY_PARAM: 'ProcessId',
    DATE_FORMAT: 'YYYY-MM-DD',
    HUMAN_DATE_FORMAT: 'D. M. YYYY',
    MAX_DOCUMENT_AGE: 23,
    POLISH_POST_REGEX: '^[0-9]{2}-[0-9]{3}',
    POLISH_BANK_NUMBER: '^(PL)?\\d{26}$',
    MIN_AGE: 18,
    MAX_AGE: 65,
};

export enum ErrorCodes {
    WrongBirthDate = 'err_bankId_birth_date_is_different',
    InvalidIdCard = 'err_idCard_is_expired',
    MissingIssueDtIdCard = 'err_idCard_valid_from_dt_is_missing',
}

export const IdCardTypes = [
    {
        Id: 1,
        Name: 'Občanský průkaz (ČR)',
        Code: 'OP',
    },
    {
        Id: 2,
        Name: 'Pas (ČR)',
        Code: 'PA',
    },
    {
        Id: 3,
        Name: 'Řidičský průkaz (ČR)',
        Code: 'RP',
    },
    {
        Id: 4,
        Name: 'Občanský průkaz (SR)',
        Code: 'OP_SK',
    },
    {
        Id: 5,
        Name: 'Pas (SR)',
        Code: 'PA_SK',
    },
    {
        Id: 6,
        Name: 'Řidičský průkaz (SR)',
        Code: 'RP_SK',
    },
    {
        Id: 7,
        Name: 'Jiný',
        Code: 'OTH',
    },
    {
        Id: 8,
        Name: 'Povolení k pobytu',
        Code: 'PP',
    },
];
