import React from 'react';
import { Field } from 'formik';
import cn from 'classnames';
import { Select, SelectOptionProps } from '../Select/Select';
import { getDataTest } from '../../utils';
import { Error } from '../Error/Error';

interface FormikSelectInputProps {
    name: string;
    label?: string;
    placeholder?: string;
    options: Array<SelectOptionProps>;
    isCenter?: boolean;
    disabled?: boolean;
    readonly?: boolean;
    required?: boolean;
    className?: string;
}

const FormikSelectInput = ({
    name,
    label,
    placeholder,
    options,
    isCenter = false,
    disabled,
    readonly = false,
    required = false,
    className,
    ...componentProps
}: FormikSelectInputProps) => {
    return (
        <div className={cn('textInputWrap', className)} {...componentProps}>
            {label && (
                <label className="myLabel" data-test={getDataTest(componentProps, 'Label')}>
                    {label}
                    {required && <span data-test="symbolRequired"> *</span>}
                </label>
            )}
            <Field id={name} name={name} placeholder={placeholder}>
                {(props: JSX.IntrinsicAttributes & FormikSelectInputProps) => {
                    return (
                        <Select
                            {...props}
                            id={name}
                            key={name}
                            name={name}
                            options={options}
                            isCenter
                            disabled={disabled}
                            data-test={getDataTest(componentProps)}
                        />
                    );
                }}
            </Field>
            <Error name={name} data-test={getDataTest(componentProps, 'Error')} />
        </div>
    );
};

export default FormikSelectInput;
